/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Select } from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import PersonalDataWork from './PersonalDataWork'
import { WorkingHoliday } from '../../Data/WorkingHolidayCountries'
import { YoungProfessionals } from '../../Data/YoungProfessionalsCountries'
import { InternationalCoOp } from '../../Data/InternationalCoOpCountries'
import { Checkbox } from 'antd'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}

const Work: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
	} = rootStore.familyStore
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [etaCountry, setEtaCountry] = React.useState(currentMember.etaCountry)
	const [usVisa, setUSVisa] = React.useState(false)
	const [showEtaImmiland, setShowEtaImmiland] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const [displayErrors, setDisplayErrors] = React.useState(false)
	const [checkboxChecked, setCheckboxChecked] = useState(false)
	const [selectedButton, setSelectedButton] = useState(null)

	const handleButtonClick = (value: any) => {
		setSelectedButton(value);
		handleChange('wantValidationLmiaExemptJobOffer', value)
	}

	useEffect(() => { }, [currentMember])

	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])

	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])

	useEffect(() => { }, [open])

	useEffect(() => { }, [principalUsed])

	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateEtaCountry = (nationality: string) => {
		const isEtaCountry = ETA.find(
			(etas) => etas.country.toLocaleLowerCase() === nationality.toLocaleLowerCase()
		)
		setEtaCountry(false)
		setShowEtaImmiland(false)
		handleChange('etaCountry', false)
		handleChange('validEta', false)
		if (isEtaCountry) {
			handleChange('etaCountry', true)
			handleChange('validEta', true)
			setEtaCountry(true)
		}
		validateBrazil()
	}

	const validateBrazil = () => {
		if (currentMember.nationality.toLocaleLowerCase() === 'brazil') {
			setUSVisa(true)
			if (currentMember.etaRejection === 'si') {
				handleChange('validEta', false)
				setShowBiometricSelect(true)
				setShowEtaImmiland(false)
			} else {
				if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'no') {
					handleChange('validEta', false)
					setShowBiometricSelect(true)
					setShowEtaImmiland(false)
				} else if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'si') {
					handleChange('validEta', true)
					setShowBiometricSelect(false)
					//  handleChange('hasBiometrics', false)
					setShowEtaImmiland(true)
				}
			}
		} else {
			setUSVisa(false)
		}
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}

	const popupDisplayTeer = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p style="text-align: justify;">En el siguiente link podra verificar en que categoria se encuentra la ocupacion de su pareja:</p>' +
				'<br><a style="color: red; text-decoration: underline;"  target="_blank" href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/find-national-occupation-code.html">https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/find-national-occupation-code.html</a>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupLMIAWithImmiland = () => {
		const checkboxText = 'Aceptar';
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p>Si su empleador aún no ha obtenido el LMIA, es esencial que nos comuniquemos con él, le pedimos proporcionarnos los datos de contacto en la parte final de la cotización.</p>' +
				'<p>Recuerde que no podrá solicitar su permiso de trabajo cerrado hasta que tengamos el LMIA aprobado.</p>' +
				`<input type="checkbox" id="checkbox-terms">
        <label for="checkbox-terms">${checkboxText}</label>`,
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
			preConfirm: () => {
				const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
				if (!checkbox?.checked) {
					Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
					return false;
				}
			},
		})
	}

	const popupTeers = () => {
		const checkboxText = 'Aceptar';
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p>Actualmente, algunas parejas de trabajadores en ocupaciones de categoría TEER 4 y 5 pueden obtener un permiso de trabajo abierto. </p>' +
				'<p>Sin embargo, la mayoría de las ocupaciones de esta categoría no están incluidas en esta política. </p>' +
				'<p>Si contrata nuestro servicio, nuestro equipo legal revisará su elegibilidad posteriormente.</p>' +
				`<input type="checkbox" id="checkbox-terms">
        <label for="checkbox-terms">${checkboxText}</label>`,
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
			preConfirm: () => {
				const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
				if (!checkbox?.checked) {
					Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
					return false;
				} else {
					setStep(9)
				}
			},
		})
	}

	const popupDisplayLMIA = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p>Una evaluación del impacto en el mercado laboral (LMIA) es un documento que puede necesitar un empleador en Canadá antes de contratar a un trabajador extranjero.	<br> Una vez que el empleador obtiene el LMIA el trabajador puede solicitar un permiso de trabajo cerrado.</p>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupCAQ = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				"<p>El Certificat d'acceptation du Québec (CAQ) es requerido para la mayoría de los estudiantes y trabajadores extranjeros temporales que tienen la intención de residir en la provincia de Quebec.</p>",
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupNoElegiblePGWP = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				"<p>En base a su respuesta, usted no es elegible para aplicar a un permiso de trabajo abierto como pareja de un estudiante.</p>" +
				'<p>Por favor agende una cita con nuestros consultores para redefinir su estrategia migratoria <a style=" text-align: left; color: #bf3c3c" target="_blank" href="https://www.immilandcanada.com/migracion/consultas"><b>https://www.immilandcanada.com/consultas</b></a></p>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupNoPayForValidation = () => {
		const checkboxText = 'Aceptar';
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p style="color: black; text-align: left">Por favor solicite a su empleador llenar el siguiente formulario: </p> <br>' +
				'<a style="color: black; text-align: left; color: #bf3c3c" target="_blank" href="https://en.immilandcanada.com/reclutamiento/lmia"><b>https://en.immilandcanada.com/reclutamiento/lmia</b></a><br></br>' +
				'<p color: black>para proceder a realizar el contrato de prestación de servicios para la solicitud de LMIA-exemption.</p>' +
				'<p color: black>Recuerde que hasta que no se reciba la aprobación del <b>código de LMIA-exemption</b>, no es posible aplicar a su permiso de trabajo cerrado.</p>' +
				`<input type="checkbox" id="checkbox-terms">
        <label for="checkbox-terms">${checkboxText}</label>`,
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
			preConfirm: () => {
				const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
				if (!checkbox?.checked) {
					Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
					return false;
				}
			},
		})
	}

	return (
		<div className='my-4 h-100' >
			<Box>
				<>
					<form
						className='w-full'
						id='fromEl'
						style={{ fontFamily: 'Lato, sans-serif' }}>
						<div style={{ padding: '30px' }}
							className='flex flex-wrap mb-6'>
							{/* start page 3 */}
							{step === 3 ? <div>
								<div className='container-aplication'>
									<div className='flex flex-wrap justify-center'>
										<BoxModal>
											<Label>Seleccione el país emisor de su pasaporte</Label>
											<div>
												<CountrySelector
													handleChange={(e: any) => {
														{
															e.target.value != '' ?
																handleChange('passportCountry', e.target.value) :
																handleChange('passportCountry', undefined)
														}
														validateEtaCountry(e.target.value)
														{
															e.target.value != '' ?
																handleChange('countryOfCitizenship', e.target.value) :
																handleChange('countryOfCitizenship', undefined)
														}
													}}
													value={currentMember.passportCountry || ''}
													label='Seleccione el país emisor de su pasaporte'
												/>
											</div>
											{!currentMember.passportCountry &&
												displayErrors === true &&
												<span className='text-tomato-500 text-xs italic'
													style={{
														marginTop: '-11px',
														marginBottom: '11px'
													}}>
													Debe seleccionar el pais emisor  de su pasaporte
												</span>}
										</BoxModal>
										<BoxModal >
											<Label>Provincia de destino:</Label>
											<ProvinceSelector className='custom-input'
												handleChange={(e: any) => {
													{
														e.target.value != '' ?
															handleChange('province', e.target.value) :
															handleChange('province', undefined)
													}
												}}
												value={currentMember.province || ''}
												label='Provinces'
											/>
											{!currentMember.province &&
												displayErrors === true &&
												<span className='text-tomato-500 text-xs italic'
													style={{
														marginTop: '-11px',
														marginBottom: '11px'
													}}>Debe seleccionar una provincia
												</span>}
										</BoxModal>
									</div>
									<div className='button-container-disc2'
										style={{
											display: 'flex',
											width: '100%',
											gap: '3rem',
											justifyContent: 'center',
											marginTop: '2rem'
										}}>
										<Button
											onClick={() => setStep(2)}
											className='backbutton-disc2'>
											Atras
										</Button>
										<Button
											type='button'
											onClick={() => {
												if (!currentMember.province || !currentMember.passportCountry) {
													setDisplayErrors(true);
												} else {
													setDisplayErrors(false);
													setStep(4);
												}
											}}
											className='startbutton-disc2'>
											Siguiente
										</Button>
									</div>
								</div>
								{/* end page 3 */}
							</div> : null}

							{/* start page 4 */}
							{step === 4 ?
								<>
									<div>
										<div>
											<div className='titledivquestions'>
												<h1>Seleccione una de las siguientes opciones</h1>
											</div>
											<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
												<div>
													<button className='rcorners2'
														onClick={() => {
															setStep(5)
															handleChange('typeOfWorkPermit', "Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense")
														}}>
														<p>Permiso de trabajo cerrado </p>
													</button>
												</div>
												<div>
													<button className='rcorners2'
														onClick={() => {
															setStep(9)
															handleChange('typeOfApplication', "PGWP")
															handleChange('typeOfWorkPermit', "PGWP")
														}}>
														<p>PGWP</p>
													</button>
												</div>
												{(WorkingHoliday.find((holidays) => holidays.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) &&
													<div>
														<button className='rcorners2'
															onClick={() => {
																setStep(9)
																handleChange('typeOfWorkPermit', "Postulación a Working Holiday")
															}}>
															<p>Postulación a Working Holiday</p>
														</button>
													</div>)}
												{(YoungProfessionals.find((young) => young.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) &&
													<div>
														<button className='rcorners2'
															onClick={() => {
																setStep(9)
																handleChange('typeOfWorkPermit', "Postulación a Young Professionals")
															}}>
															<p>Young Professionals</p>
														</button>
													</div>)}
												{(InternationalCoOp.find((CoOp: any) => CoOp.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) &&
													<div>
														<button className='rcorners2'
															onClick={() => {
																setStep(9)
																handleChange('typeOfWorkPermit', "Postulación a International CoOp")
															}}>
															<p>International Co-op(Internship)</p>
														</button>
													</div>)}
												<div>
													<button className='rcorners2' onClick={() => {
														setStep(5)
														handleChange('typeOfWorkPermit', "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá")
													}}>
														<p>Permiso de trabajo abierto para parejas</p>
													</button>
												</div>
											</div>
										</div>
										<div className='button-container-disc2'
											style={{
												display: 'flex',
												width: '100%',
												gap: '3rem',
												justifyContent: 'center',
												marginTop: '2rem'
											}}>
											<Button
												type='button'
												onClick={() => setStep(3)}
												className='backbutton-disc2'>
												Atras
											</Button>
										</div>
									</div>
								</> : null}
							{/* end page 4 */}

							{/* start page 5 */}
							{step === 5 &&
								currentMember.typeOfWorkPermit === "Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense" ?
								<>
									<div>
										<div>
											<div className='titledivquestions'>
												<h1>¿Usted califica como trabajador extranjero exento de &#160;
													<button type='button' onClick={() => popupDisplayLMIA()}
														style={{
															color: 'blue',
															textDecoration: 'underline'
														}}>LMIA</button>?
												</h1>
											</div>
											<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
												<div>
													<button
														className='rcorners2'
														onClick={() => {
															setStep(6)
															handleChange('typeOfClosedWorkPermit', "REQUIERE de una evaluación de impacto del mercado laboral")
														}}>
														<p>No</p>
													</button>
												</div>
												<div>
													<button className='rcorners2'
														onClick={() => {
															setStep(8)
															handleChange('typeOfClosedWorkPermit', "ESTÁ EXENTA de una evaluación de impacto del mercado laboral")
														}}>
														<p>Si</p>
													</button>
												</div>
											</div>
										</div>
										<div className='button-container-disc2'
											style={{
												display: 'flex',
												width: '100%',
												gap: '3rem',
												justifyContent: 'center',
												marginTop: '2rem'
											}}>
											<Button
												type='button'
												className='backbutton-disc2'
												onClick={() => setStep(4)} >
												Atras
											</Button>
										</div>
									</div>
								</> : null}
							{step === 5 &&
								currentMember.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá' &&
								<>
									<div>
										<div>
											<div className='titledivquestions'>
												<h1>¿Qué estatus tiene su pareja en Canadá?</h1>
											</div>
											<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
												<div>
													<button
														className='rcorners2'
														onClick={() => {
															setStep(8)
															handleChange('typeOfStatusConyuge', "Trabajador")
														}}>
														<p>Trabajador</p>
													</button>
												</div>
												<div>
													<button className='rcorners2' onClick={() => {
														setStep(8)
														handleChange('typeOfStatusConyuge', "Estudiante")
													}}>
														<p>Estudiante </p>
													</button>
												</div>
											</div>
										</div>
										<div className='button-container-disc2'
											style={{
												display: 'flex',
												width: '100%',
												gap: '3rem',
												justifyContent: 'center',
												marginTop: '2rem'
											}}>
											<Button
												type='button'
												className='backbutton-disc2'
												onClick={() => setStep(4)}>
												Atras
											</Button>
										</div>
									</div>
								</>}
							{/* end page 5*/}

							{/* start page 6 REquire LMIA*/}
							{step === 6 &&
								currentMember.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral" ?
								<>
									<div>
										<div>
											<div className='titledivquestions'>
												<h1>Para solicitar un permiso de trabajo cerrado con LMIA,
													<br /> su empleador debe haber obtenido previamente una evaluación de impacto en el mercado laboral (LMIA).
													<br />¿Su empleador ya ha completado este paso?</h1>
											</div>
											<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
												<div>
													<button type='button' className='rcorners2' onClick={() => {
														handleChange('wantAddLmiaPositive', "no")
														currentMember.province === 'Quebec' ? setStep(7) : setStep(9)
													}}>
														<p>Si</p>
													</button>
												</div>
												<div>
													<button type='button' className='rcorners2' onClick={() => {
														popupLMIAWithImmiland()
														handleChange('wantAddLmiaPositive', "si")
														currentMember.province === 'Quebec' ? setStep(7) : setStep(9)
													}}>
														<p>No</p>
													</button>
												</div>
											</div>
										</div>
										<div className='button-container-disc2'
											style={{
												display: 'flex',
												width: '100%',
												gap: '3rem',
												justifyContent: 'center',
												marginTop: '2rem'
											}}>
											<Button
												type='button'
												className='backbutton-disc2'
												onClick={() => setStep(5)}>
												Atras
											</Button>
										</div>
									</div>
								</> : null}
							{/* end page 6*/}

							{/* start page 7 */}
							{step === 7 && currentMember.province === "Quebec" ?
								<>
									<div>
										<div>
											<div className='titledivquestions'>
												<h1>Para solicitar un permiso de trabajo cerrado con LMIA y/o permiso de estudio para dependientes en Quebec,
													<br /> es necesario obtener previamente un &#160;
													<button type='button'
														onClick={() => popupCAQ()}
														style={{
															color: 'blue',
															textDecoration: 'underline'
														}}>CAQ</button><br />
													¿Desea incluir este servicio?</h1>
											</div>
											<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
												<div>
													<button className='rcorners2' onClick={() => {
														setStep(9)
														handleChange('wantCaq', "si")
													}}>
														<p>SI</p>
													</button>
												</div>
												<div>
													<button className='rcorners2'
														onClick={() => {
															setStep(9)
															handleChange('wantCaq', "no")
														}}>
														<p>NO</p>
													</button>
												</div>
											</div>
										</div>
										<div className='button-container-disc2'
											style={{
												display: 'flex',
												width: '100%',
												gap: '3rem',
												justifyContent: 'center',
												marginTop: '2rem'
											}}>
											<Button
												className='backbutton-disc2'
												onClick={() => setStep(6)}>
												Atras
											</Button>
										</div>
									</div>
								</> : null}
							{/* end page  7*/}
							{/* start page 8 */}
							{step === 8 &&
								currentMember.typeOfClosedWorkPermit === "ESTÁ EXENTA de una evaluación de impacto del mercado laboral" ?
								<>
									<div>
										<div>
											{/* <div className='titledivquestions'>
												<h1>Para solicitar un permiso de trabajo cerrado exento de LMIA,
													<br /> es necesario validar previamente la oferta laboral.
													<br /> Para agilizar el proceso:
													<b>¿Desea incluir los gastos de validación por su empleador?</b>
												</h1>
											</div>
											<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
												<div>
													<button
														className={`rcorners2 ${selectedButton === "si" ? 'selected' : ''}`}
														onClick={(e) => {
															e.preventDefault();
															handleButtonClick("si");
														}}>
														<p>SI</p>
													</button>
													<p className='bottoninfo'></p>
												</div>
												<div>
													<button
														className={`rcorners2 ${selectedButton === "no" ? 'selected' : ''}`}
														onClick={(e) => {
															popupNoPayForValidation();
															e.preventDefault();
															handleButtonClick("no");
														}}>
														<p>NO</p>
													</button>
													<p className='bottoninfo'></p>
												</div>
											</div> */}
											<div style={{ height: '100%' }}
												className="rejected-doc-yellow mt-2 mb-2">
												<div className="flex flex-row items-start gap-4 items-center"
													style={{ width: '100%' }}>
													<div className="flex flex-col"
														style={{ width: '100%' }}>
														<div style={{
															display: 'flex',
															alignItems: 'center',
															marginTop: '20px'
														}}>
															<img src="/icons/warning.svg"
																alt="WarningIcon"
																style={{
																	width: '40px',
																	height: '40px',
																	marginRight: '10px'
																}} />
															<div style={{
																gap: '5px',
																display: 'flex',
																flexDirection: 'column'
															}}>
																<p style={{
																	color: '#9D5425',
																	fontSize: '18px',
																	margin: '0px',
																	fontWeight: '700'
																}}>
																	<b>¿Desea recibir una plantilla para que su empleador conozca la estructura general de una
																		<br /> oferta de empleo?</b>
																</p>
																<p style={{
																	color: '#9D5425',
																	fontSize: '18px',
																	margin: '0px',
																	fontWeight: '400'
																}}>
																	<a>Esta plantilla no debe considerarse como representación legal en  asuntos de derecho
																		<br /> laboral. Su empleador es responsable de determinar  el alcance de este acuerdo y de
																		<br /> redactar las cláusulas finales.</a> <br />
																	<b style={{
																		fontWeight: '600',
																		fontSize: '17px'
																	}}>Se añadirá un cargo de $300 CAD</b>
																	<a style={{ fontWeight: '400' }}>a su cotización</a>
																</p>
															</div>
														</div>
														<div style={{
															backgroundColor: '#F9FAFB',
															width: '100%',
															borderRadius: '10px',
															marginTop: '20px'
														}}>
															<div style={{
																fontSize: '1.1rem',
																margin: '20px',
																display: 'flex',
																flexDirection: 'row',
																alignItems: 'center',
																gap: '15px'
															}}>
																<Checkbox onChange={(e) => {
																	setCheckboxChecked(e.target.checked);
																	currentMember.employletter = e.target.checked;
																}} />
																<b style={{
																	fontSize: '16px',
																	fontWeight: '400',
																	color: 'black'
																}}>Si, deseo añadir este servicio adicional, tomando en cuenta que es necesario que mi
																	<br /> empleador consulte un abogado en derecho laboral de la provincia donde la empresa exista
																	<br /> para que el abogado revise la oferta y se asegure que la misma corresponde y respeta las
																	<br /> cláusulas laborales de la provincia.</b>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='button-container-disc2'
											style={{
												display: 'flex',
												width: '100%',
												gap: '3rem',
												justifyContent: 'center',
												marginTop: '2rem'
											}}>
											<Button
												className='backbutton-disc2'
												onClick={() => setStep(5)}>
												Atras
											</Button>
											<Button
												className={`startbutton-disc2 ${selectedButton && checkboxChecked ? '' : 'button-disabled'}`}
												onClick={() => setStep(9)}
												/* disabled={!selectedButton} */>
												Siguiente
											</Button>
										</div>
									</div>
								</> : null}

							{step === 8 && currentMember.typeOfStatusConyuge === 'Estudiante' && <>
								<div>
									<div>
										<div className='titledivquestions'>
											<h1>¿Es usted
												<a target="_blank" href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/work/help-your-spouse-common-law-partner-work-canada.html"
													style={{
														color: 'blue',
														textDecoration: 'underline'
													}}>elegible</a> para obtener un permiso abierto de trabajo?</h1>
										</div>
										<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
											<div>
												<button type='button' className='rcorners2' onClick={() => {
													setStep(9)
													handleChange('elegiblePGWP', "si")
												}}>
													<p>SI</p>
												</button>
												<p className='bottoninfo'></p>
											</div>
											<div>
												<button type='button' className='rcorners2' onClick={() => {
													popupNoElegiblePGWP()
													handleChange('elegiblePGWP', "no")
												}}>
													<p>NO</p>
												</button>
												<p className='bottoninfo'></p>
											</div>
										</div>
									</div>
									<div className='button-container-disc2'
										style={{
											display: 'flex',
											width: '100%',
											gap: '3rem',
											justifyContent: 'center',
											marginTop: '2rem'
										}}>
										<Button
											type='button'
											className='backbutton-disc2'
											onClick={() => setStep(5)}>
											Atras
										</Button>
									</div>
								</div>
							</>}

							{step === 8 && currentMember.typeOfStatusConyuge === 'Trabajador' && <>
								<div className='containerteer'>
									<div>
										<Label>
											¿En que categoria
											<i style={{
												color: '#bf3c3c'
											}}
												onClick={popupDisplayTeer}>&#160;(TEER)&#160; </i>
											se clasifica la ocupación de su pareja?
										</Label>
										<Select className='custom-input'
											name='Tiene hijos dependientes que desee agregar a esta solicitud'
											defaultValue={currentMember.kindOfTeer}
											style={{ marginTop: '2rem' }}
											labelId='demo-simple-select-label'
											label='Tiene hijos dependientes que desee agregar a esta solicitud'
											value={currentMember.kindOfTeer}
											onChange={(e: any) => {
												{ e.target.value === '4' ? popupTeers() : null }
												{ e.target.value === '5' ? popupTeers() : null }
												{ e.target.value != '' ? handleChange('kindOfTeer', e.target.value) : handleChange('kindOfTeer', undefined) }
												{ e.target.value === '0' ? setStep(9) : null }
												{ e.target.value === '1' ? setStep(9) : null }
												{ e.target.value === '2' ? setStep(9) : null }
												{ e.target.value === '3' ? setStep(9) : null }
												/* { e.target.value === '4' ? setStep(9) : null }
												{ e.target.value === '5' ? setStep(9) : null } */
											}}>
											<option value=''>Seleccione</option>
											<option value='0'>TEER 0</option>
											<option value='1'>TEER 1</option>
											<option value='2'>TEER 2</option>
											<option value='3'>TEER 3</option>
											<option value='4'>TEER 4</option>
											<option value='5'>TEER 5</option>
										</Select>
									</div>
									{!currentMember.kindOfTeer && displayErrors === true &&
										<span className='text-tomato-500 text-xs italic'
											style={{
												marginTop: '-11px',
												marginBottom: '11px'
											}}>
											Debe indicar si desea agregar a hijos dependientes
										</span>}
									<div className='button-container-disc2'
										style={{
											display: 'flex',
											width: '100%',
											gap: '3rem',
											justifyContent: 'center',
											marginTop: '2rem'
										}}>
										<Button
											type='button'
											className='backbutton-disc2'
											onClick={() => setStep(5)}>
											Atras
										</Button>
									</div>
								</div>
							</>}
							{step === 9 ?
								<PersonalDataWork closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} /> :
								null}
						</div>
					</form>
				</>
			</Box>
		</div>
	)
}
export default Work
