
import React, { Fragment } from 'react'
import { Button } from 'Components/Button'

import colores from './gradient-background.png'
import colores2 from './gradient-background-law.png'
import '../../FormBuilder/InfoDisclaimer/style/style.css'
import youtube from '../../FormBuilder/icons/YouTube.svg'
import instagram from '../../FormBuilder/icons/Instagram.svg'
import facebook from '../../FormBuilder/icons/Facebook.svg'
import twitter from '../../FormBuilder/icons/Twitter.svg'
import tiktok from '../../FormBuilder/icons/Tiktok.svg'
import linkedin from '../../FormBuilder/icons/Linkedin.svg'

interface IProps {
	isOpen: boolean
	closeModal: () => void
	children: React.ReactNode
	title: string
	description?: string
	width?: string
	height?: string
	closeButton?: boolean | true
	closeButtonLabel?: string
}

const DialogComponent: React.FC<IProps> = ({
	isOpen,
	children,
	closeModal,
	title,
	closeButtonLabel = 'Cerrar',
	closeButton = true,
}) => {
	return (
		<>
			{isOpen && (
				<>
					<div style={{ backgroundColor: 'white' }} className='flex justify-center items-center overflow-x-hidden p-0 fixed w-full inset-0 z-50 outline-none focus:outline-none bg-gray-600 bg-opacity-90 sm:w-full'>
						<div className='relative' style={{ width: '100%', height: '100%' }}>
							<div style={{ width: '100%', height: '100%' }} className='border-0 rounded-lg shadow-xl relative bg-white outline-none focus:outline-none '>
							<div className='nav-bar1'
            style={{
				position: 'fixed',
				zIndex: 1000,
				width: '100%',
				top: 0,
				height: '8px',
              marginBottom: '10px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
								>
			
								</div>
								<div className='flex  items-start justify-between pl-6 pt-2 pr-8    '>
									<h3 className='text-2xl font=semibold'>{title}</h3>
									{closeButton && (
										<button
											className='bg-transparent border-0 text-black float-right'
											onClick={() => closeModal()}
										>
											<span className='text-black opacity-7 h-1 w-6 text-xl block bg-gray-400 py-0 rounded-full '>
												x
											</span>
										</button>
									)}
								</div>
								<div style={{ backgroundColor: 'white' }} className='relative p-6 flex-auto h-full'>
									{children}
								</div>
								{closeButton && (
									<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
										<Button type='button' onClick={() => closeModal()}>
											{closeButtonLabel}
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default DialogComponent
