import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './preEvaluation.css';
import Avatar1 from './assets/avatar/avatar-a.svg'
import Avatar2 from './assets/avatar/avatar-b.svg'
import Avatar3 from './assets/avatar/avatar-c.svg'
import Avatar4 from './assets/avatar/avatar-d.svg'
import Avatar5 from './assets/avatar/avatar-e.svg'
import Avatar6 from './assets/avatar/avatar-f.svg'
import StudyIcon from './assets/icon/estudio-studies.svg'
import WorldIcon from './assets/icon/world.svg'
import WorldIconWhite from './assets/icon/world-white.svg'
import ArrowRight from './assets/icon/arrow-right-circle.svg'
import ArrowRightDisable from './assets/icon/arrow-right-circle-disable.svg'
import ArrowLeft from './assets/icon/arrow-left-circle.svg'
import { CountrySelectorPE } from './countrySelectorPE';
import { sendPreEvaluationData } from './_request/preevaluationrequest';


interface IProps {
  setShowResult: Dispatch<SetStateAction<boolean>>
  setPreEvaluationAnswers: Dispatch<SetStateAction<any>>
  preEvaluationAnswers: any
}


const MainPreEvaluationForm: React.FC<IProps> = ({ setShowResult, setPreEvaluationAnswers, preEvaluationAnswers }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [seleccionado, setSeleccionado] = useState('');
  const [seleccionados, setSeleccionados] = useState('');
  const [iconoDentro, setIconoDentro] = useState(StudyIcon);
  const [iconoFuera, setIconoFuera] = useState(WorldIcon);
  const [nivel, setNivel] = useState<number>(0);
  const [nivelFrances, setNivelFrances] = useState<number>(0);
  const [emailError, setEmailError] = useState('');
  const [previousPage, setPreviousPage] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [confDisclaimer, setConfDisclaimer] = useState(true);
  const [disclaimer, setDisclaimer] = useState(true);

  const handleCheckboxChange = (e: { target: any }) => {
    const checkbox = e.target; // Actualizar el estado del checkbox
    setConfDisclaimer(!checkbox.checked); // Actualizar el estado del botón
    if (checkbox.checked) {
      checkbox.classList.add('checked');
    } else {
      checkbox.classList.remove('checked');
    }
  };

  const handleShowResults = () => {
    sendPreEvaluationData(preEvaluationAnswers)
      .then(() => {
        setShowResult(true);
      })
      .catch((error) => {
        console.error('Error al enviar datos de la evaluación preliminar:', error);
      });
  };

  const handleNextPage = () => {
    const nextPage = calculateNextPage(currentPage, seleccionado);
    setPreviousPage(currentPage !== null ? currentPage : 0);
    setTransitioning(true);

    setTimeout(() => {
      setCurrentPage(nextPage);
      setTransitioning(false);
    }, 100);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    if (newValue === 0) {
      setNivel(newValue);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        english: '',
      }));
    }
    if (newValue === 1) {
      setNivel(0.7);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        english: 'Nulo',
      }));
    }
    if (newValue === 2) {
      setNivel(1.6);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        english: 'Bajo',
      }));
    }
    if (newValue === 3) {
      setNivel(2.6);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        english: 'Medio',
      }));
    }
    if (newValue === 4) {
      setNivel(newValue);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        english: 'Avanzado',
      }));
    }

  };
  const handleChangeFrances = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);

    if (newValue === 0) {
      setNivelFrances(newValue);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        french: '',
      }));
    }
    if (newValue === 1) {
      setNivelFrances(0.7);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        french: 'Nulo',
      }));
    }
    if (newValue === 2) {
      setNivelFrances(1.6);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        french: 'Bajo',
      }));
    }
    if (newValue === 3) {
      setNivelFrances(2.6);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        french: 'Medio',
      }));
    }
    if (newValue === 4) {
      setNivelFrances(newValue);
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        french: 'Avanzado',
      }));
    }
  };
  const handleClick = (tipo: React.SetStateAction<string>) => {
    setSeleccionado(tipo);
    if (tipo === 'dentro') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        location: 'dentro',
      }));
      setIconoDentro(seleccionado === 'dentro' ? StudyIcon : StudyIcon);

    } else {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        location: 'fuera',
      }));
      setIconoFuera(seleccionado === 'fuera' ? WorldIcon : WorldIconWhite);
    }
  };

  const handleButtonClick = (buttonNumber: string) => {
    setSeleccionados(buttonNumber);
    if (buttonNumber === '1') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'estudios',
      }));
    } else if (buttonNumber === '2') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'residencia',
      }));
    } else if (buttonNumber === '3') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'trabajo',
      }));
    } else if (buttonNumber === '4') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'turismo',
      }));
    }
  };

  const handleButtonClickInside = (buttonNumber: string) => {
    setSeleccionados(buttonNumber);
    if (buttonNumber === '1') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'turismo',
      }));
    } else if (buttonNumber === '2') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'estudios',
      }));
    } else if (buttonNumber === '3') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'trabajo',
      }));
    } else if (buttonNumber === '4') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'residencia',
      }));
    } else if (buttonNumber === '5') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'refugio',
      }));

    } else if (buttonNumber === '6') {
      setPreEvaluationAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        inmigrationArea: 'sin status',
      }));
    }
  };

  const handleInputNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPreEvaluationAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      name: value, // Actualizar el campo 'name' con el valor del input
    }));
  };

  const handleInputEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPreEvaluationAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      email: value,
    }));

    // Expresión regular para validar la dirección de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError('Debe ingresar un correo electrónico válido');
    } else {
      setEmailError('');
    }
  };

  const calculateNextPage = (currentPage: any, seleccionado: any) => {
    // Lógica para determinar la siguiente página según las condiciones específicas
    if (currentPage === 0) {
      return seleccionado === 'dentro' ? 20 : seleccionado === 'fuera' ? 1 : currentPage;
    } else {
      // Otra lógica para las páginas siguientes si es necesario
    }
  };

  const jobAreas = [
    { value: 'artes-culinarias-hospitalidad-turismo', label: 'Artes Culinarias / Hospitalidad y Turismo / Gestión de restaurantes y hoteles' },
    { value: 'business-administration', label: 'Business and Administration (áreas relacionadas a gestión, servicio al cliente y negocios)' },
    { value: 'biologia-biotecnologia-quimica', label: 'Biología / Biotecnología / Química' },
    { value: 'ciencias-sociales', label: 'Ciencias sociales (economía, política, sociología, trabajo social, psicología, antropología, periodismo)' },
    { value: 'ciencias-salud', label: 'Ciencias de la salud (medicina, enfermería, administración de la salud, nutrición)' },
    { value: 'diseno-arquitectura-ingenieria-construccion', label: 'Diseño industrial / Arquitectura / Ingeniería civil / gestión en construcción' },
    { value: 'diseno-grafico-artes-visuales-animacion-video', label: 'Diseño Gráfico / Artes visuales / Animación y video' },
    { value: 'derecho-leyes-funcionario-publico', label: 'Derecho / Leyes / Funcionario público (policía, bomberos, entre otros)' },
    { value: 'docencia-educacion-infancia', label: 'Docencia en general / Educación de la primera infancia' },
    { value: 'ingenieria-sistemas-informatica-ciencias-ti', label: 'Ingeniería en sistemas / Informática / Ciencias computacionales / TI' },
    { value: 'ingenierias', label: 'Ingenierías (industrial, mecánica, alimentos, agronomía, electrónica, entre otros)' },
    { value: 'estudios-religiosos-teologia', label: 'Estudios religiosos / Teología' },
    { value: 'gestion-deporte-eventos-entretenimiento', label: 'Gestión de deporte o eventos / Entretenimiento' },
    { value: 'tecnicos-tecnologos', label: 'Técnicos / Tecnólogos (mecánica, carpintería, soldaduría, plomería, instrumental, electricidad)' }
  ];
  return (
    <div className='first-form-main-container'>
      <div className="first-form-container">
        <div className="first-form-container-inner">
          <h1 className='first-form-white-title'>¿Cuál será su</h1>
          <h1 className='first-form-yellow-title'>próxima experiencia?</h1>
          <h3 className='first-form-white-subtitle'>Realice aquí su pre-evaluación sin costo</h3>
        </div>
        <div className='first-form-carrusel-container'>
          {/* Fuera de Canada */}
          {/* Pagina 1 */}
          {currentPage === 0 ?
            <>
              {disclaimer === true ?
                <div className='pre-evaluation-disclaimer'>
                  <div className='pre-evaluation-disclaimer-container'>
                    <div className='pre-evaluation-disclaimer-text'>
                      <p style={{ fontSize: '16px' }}>
                        Esta evaluación <b style={{color: '#5D5FEF'}}>no puede ser tomada como una asesoría migratoria.</b>
                        El resultado de las preguntas que se hacen aquí constituye única y exclusivamente
                        una <b style={{color: '#5D5FEF'}}>guía general para que el candidato pueda encaminar su proceso</b> y desarrollar
                        por sí mismo o con la representación de un abogado o consultor regulado su proceso migratorio.<br />
                        Es importante tomar en cuenta que existen muchos otros factores a considerar para poder emitir
                        una evaluación precisa sobre las opciones reales de inmigración a Canadá por lo que le recomendamos ver el
                        resultado e investigar por cuenta propia o agendar una cita migratoria para evaluar todo en detalle.<br />
                        RECOMENDACION: <b style={{color: '#5D5FEF'}}>Si usted ya conoce el plan migratorio que desea tomar</b> no es recomendable entonces hacer esta evaluación
                        sino proceder con agendar una consulta migratoria o contactar nuestros servicios para solicitud de visado.
                      </p>
                    </div>
                    <div className='pre-evaluation-disclaimer-botons'>
                      <div className='card-1-expired' style={{ width: '700px', height: '68px', top: '97px', gap: '0px', borderRadius: '10px 0px 0px 0px', opacity: '0px', backgroundColor: '#F9FAFB', display: 'flex', alignItems: 'center', padding: '16px 35px 16px 35px' }}>
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          onChange={handleCheckboxChange}
                        />
                        <p style={{ fontFamily: 'Lato', fontSize: '16px', fontWeight: '400', lineHeight: '24px', textAlign: 'left', color: '#111928' }}>Entiendo y confirmo lo informado en el texto anterior.</p>
                      </div>
                      <button
                        className={`startbutton-disc2 ${confDisclaimer ? 'button-disabled' : ''}`} disabled={confDisclaimer}
                        onClick={() => setDisclaimer(false)}>
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div> :
                <div
                  className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
                  onAnimationEnd={() => setTransitioning(false)}>
                  <>
                    <div className='text-question-container'>
                      <h3 className='title-condition'>¿Dónde te encuentras?</h3>
                      <div className='btn-question-container-carrusell'>
                        <button
                          className={seleccionado === 'dentro' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                          onClick={() => handleClick('dentro')}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            <path fill={seleccionado === 'dentro' ? 'white' : 'black'} d="M12 12h4v4h-4z" />
                          </svg>
                          <p>Dentro de Canadá</p>
                        </button>
                        <button className={seleccionado === 'fuera' ? 'boton-blue-first-form' : 'boton-white-first-form'} onClick={() => handleClick('fuera')}>
                          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64" />
                            <path fill={seleccionado === 'dentro' ? 'white' : 'black'} d="M12 12h4v4h-4z" />
                          </svg>
                          <p>Fuera de Canadá</p>
                        </button>
                      </div>
                    </div>
                    <button
                      className={seleccionado ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                      onClick={handleNextPage}
                      disabled={!seleccionado}
                    >
                      {seleccionado ? (
                        <img src={ArrowRight} alt="ArrowRight" />
                      ) : (
                        <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                      )}
                    </button>
                  </>
                </div>}
            </> : null}
          {/* Pagina 2 */}
          {currentPage === 1 ? (
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button' onClick={() => { setCurrentPage(0); }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button><div className='text-question-container'>
                <h3 className='title-condition'>¿En qué país vive?</h3>
                <CountrySelectorPE
                  handleChange={(e: any) => {
                    setPreEvaluationAnswers((prevAnswers: any) => ({
                      ...prevAnswers,
                      country: e.target.value,
                    }));
                  }}
                  value={preEvaluationAnswers.country || ''}
                  label='Pais' />
              </div><button
                className={preEvaluationAnswers.country ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(2); }}
                disabled={!preEvaluationAnswers.country}
              >
                {preEvaluationAnswers.country ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div>) : null}
          {/* Pagina 3 */}
          {currentPage === 2 ? (
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}
            >
              <button className='first-test-arrow-button' onClick={() => { setCurrentPage(1) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Qué edad tiene?</h3>
                <div className='flex gap-4 items-center'>
                  <select
                    className='selector-pre-evaluation form-select-pre-evaluation'

                    onChange={(e: any) => {
                      const selectedAge = e.target.value;
                      setPreEvaluationAnswers((prevAnswers: any) => ({
                        ...prevAnswers,
                        age: selectedAge,
                      }));
                    }}
                    value={preEvaluationAnswers.age || ''}
                  >
                    <option value="seleccione">seleccione</option>
                    <option value="17 o menos">17 años o menos</option>
                    <option value="18-37">Entre 18 y 37 años</option>
                    <option value="38-55">Entre 38 y 55 años</option>
                    <option value="55 o mas">51 o más</option>
                  </select>

                </div>
              </div>
              <button
                className={preEvaluationAnswers.age ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(3) }}
                disabled={!preEvaluationAnswers.age}
              >
                {preEvaluationAnswers.age ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div>
          ) : null}
          {/* Pagina 4 */}
          {currentPage === 3 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(2) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es tu área de trabajo?</h3>
                <div className='flex gap-4 items-center'>
                  <select
                    style={{ maxWidth: '100%' }}
                    className='selector-pre-evaluation form-select-pre-evaluation'
                    onChange={(e: any) => {
                      setPreEvaluationAnswers((prevAnswers: any) => ({
                        ...prevAnswers,
                        jobArea: e.target.value,
                      }));
                    }}
                    value={preEvaluationAnswers.jobArea}
                  >
                    <option value="seleccione">seleccione</option>
                    {jobAreas.map((area) => (
                      <option key={area.value} value={area.value}>{area.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                className={preEvaluationAnswers.jobArea ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(4) }}
                disabled={!preEvaluationAnswers.jobArea}
              >
                {preEvaluationAnswers.jobArea ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>

            </div> : null}
          {/* Pagina 5 */}
          {currentPage === 4 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(3) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es tu nivel de estudios?</h3>
                <div className='flex gap-4 items-center'>
                  <select
                    className='selector-pre-evaluation form-select-pre-evaluation'
                    value={preEvaluationAnswers.studyLevel}
                    onChange={(e: any) => {
                      {
                        setPreEvaluationAnswers((prevAnswers: any) => ({
                          ...prevAnswers,
                          studyLevel: e.target.value,
                        }));
                      }
                    }}
                  >
                    <option value="seleccione">seleccione</option>
                    <option value="Basica">Basica</option>
                    <option value="Media">Media</option>
                    <option value="Tecnico">Técnico</option>
                    <option value="Universitaria">Universitaria Completa</option>
                    <option value="Postgrado">Postgrado</option>
                  </select> </div>
              </div>
              <button
                className={preEvaluationAnswers.studyLevel ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(5) }}
                disabled={!preEvaluationAnswers.studyLevel}
              >
                {preEvaluationAnswers.studyLevel ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div> : null}
          {currentPage === 5 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(4) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es tu nivel de Inglés?</h3>
                <div className="range-slider">
                  <input
                    type="range"
                    min="0"
                    max="4"
                    value={nivel.toString()}
                    onChange={handleChange}
                    step="1"
                    style={{ background: `linear-gradient(to right, #007bff 0%, #007bff ${nivel * 25}%, #b4b4b4 ${nivel * 25}%, #b4b4b4 100%)`, borderRadius: '30px' }}
                  />
                  <div onChange={handleChange} className="slider-thumb" style={{ left: `${nivel * 24}%` }}></div>
                  <div className="options">

                    <span className={`option ${nivel === 0 ? 'selected' : ''}`}></span>
                    <span className={`option ${nivel === 1 ? 'selected' : ''}`}>Nulo</span>
                    <span className={`option ${nivel === 2 ? 'selected' : ''}`}>Bajo</span>
                    <span className={`option ${nivel === 3 ? 'selected' : ''}`}>Medio</span>
                    <span className={`option ${nivel === 4 ? 'selected' : ''}`}>Avanzado</span>
                  </div>
                </div>
              </div>


              <button
                className={nivel ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(6) }}
                disabled={nivel === 0}
              >
                {nivel ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>

            </div>
            : null}
          {currentPage === 6 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(5) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es tu nivel de Francés?</h3>
                <div className="range-slider">
                  <input
                    type="range"
                    min="0"
                    max="4"
                    value={nivelFrances.toString()}
                    onChange={handleChangeFrances}
                    step="1"
                    style={{ background: `linear-gradient(to right, #007bff 0%, #007bff ${nivelFrances * 25}%, #b4b4b4 ${nivelFrances * 25}%, #b4b4b4 100%)`, borderRadius: '30px' }}

                  />
                  <div onChange={handleChangeFrances} className="slider-thumb" style={{ left: `${nivelFrances * 24}%` }}></div>
                  <div className="options">
                    <span className={`option ${nivelFrances === 0 ? 'selected' : ''}`}></span>
                    <span className={`option ${nivelFrances === 1 ? 'selected' : ''}`}>Nulo</span>
                    <span className={`option ${nivelFrances === 2 ? 'selected' : ''}`}>Bajo</span>
                    <span className={`option ${nivelFrances === 3 ? 'selected' : ''}`}>Medio</span>
                    <span className={`option ${nivelFrances === 4 ? 'selected' : ''}`}>Avanzado</span>
                  </div>
                </div>

              </div>
              <button
                className={nivelFrances ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(7) }}
                disabled={nivelFrances === 0}
              >
                {nivelFrances ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div>
            : null}
          {/* Pagina 8 */}
          {currentPage === 7 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(6) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Acerca de qué te gustaría saber?</h3>
                <div className='selector-landing-container-btn'>
                  <div className='selector-landing-container-btn-inner'>
                    <button className={seleccionados === '1' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClick('1')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                        <path fill="{seleccionados === 'ESTUDIOS' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>ESTUDIOS</p>
                    </button>
                    <button className={seleccionados === '2' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClick('2')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                        <path fill="{seleccionados === 'RESIDENCIA' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>RESIDENCIA</p>
                    </button>
                  </div>
                  <div className='selector-landing-container-btn-inner'>
                    <button className={seleccionados === '3' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClick('3')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                        <path fill="{seleccionados === 'TRABAJO' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>TRABAJO</p>
                    </button>
                    <button className={seleccionados === '4' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClick('4')}>
                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64" />
                        <path fill="{seleccionados === 'TURISMO' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>TURISMO</p>
                    </button>
                  </div>
                </div>
              </div>

              <button
                className={seleccionados ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(8) }}
                disabled={!seleccionados}
              >
                {seleccionados ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>

            </div> : null}
          {/* Pagina 9 */}
          {currentPage === 8 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(7) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container-input'>
                <div className='input-container-pre-evaluation'>
                  <h3 className='title-condition'>Nombre completo</h3>
                  <input
                    type="text"
                    className="input-pre-evaluation"
                    placeholder="Nombre Completo"
                    onChange={handleInputNameChange}
                    value={preEvaluationAnswers.name}
                  />
                </div>
                <div className='input-container-pre-evaluation'>
                  <h3 style={{ height: '2rem', margin: '0', fontSize: '1.5rem', marginLeft: '1rem' }}>Correo electrónico</h3>
                  <div>    <input
                    type="email"
                    className="input-pre-evaluation-email"
                    placeholder="Nombre Completo"
                    onChange={handleInputEmailChange}
                    value={preEvaluationAnswers.email}
                    style={{ minWidth: '15rem' }}
                  />
                    {emailError && <p style={{ color: 'red', fontSize: '0.8rem', margin: '0' }}>{emailError}</p>}</div>
                </div>
              </div>
              <button
                className={preEvaluationAnswers.name && preEvaluationAnswers.email && !emailError ? 'button-form-notify' : 'button-form-notify-disable'}
                disabled={!(preEvaluationAnswers.name && preEvaluationAnswers.email && !emailError)}
                style={{ gap: '10px' }}
                onClick={() => {
                  handleShowResults
                  setShowResult(true)
                }}>
                <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>VER RESULTADOS</p>
              </button>
            </div>
            : null}
          {/* Fin Fuera de  Canada */}
          {/* Dentro de Canada */}
          {/* Pagina 19 */}
          {currentPage === 20 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(0) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es la condición actual de tu estadía?</h3>
                <div className='flex flex-wrap gap-3 items-center' style={{ width: '50%' }}>
                  <div className='flex flex-wrap gap-3 items-center'>
                    <button className={seleccionados === '1' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClickInside('1')}>
                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64" />
                        <path fill="{seleccionados === 'TURISTA' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>TURISTA</p>
                    </button>
                    <button className={seleccionados === '2' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClickInside('2')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                        <path fill="{seleccionados === 'ESTUDIANTE' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>ESTUDIANTE</p>
                    </button>
                    <button className={seleccionados === '3' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClickInside('3')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                        <path fill="{seleccionados === 'TRABAJADOR' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>TRABAJADOR</p>
                    </button>

                  </div>
                  <div className='flex flex-wrap gap-3 items-center'>
                    <button className={seleccionados === '4' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClickInside('4')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                        <path fill="{seleccionados === 'RESIDENTE PERMANENTE' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p style={{ lineHeight: '10px' }}>RESIDENTE PERMANENTE</p>
                    </button>
                    <button className={seleccionados === '5' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClickInside('5')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                        <path fill="{seleccionados === 'REFUGIADO' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p>REFUGIADO</p>
                    </button>
                    <button className={seleccionados === '6' ? 'boton-blue-first-form' : 'boton-white-first-form'}
                      onClick={() => handleButtonClickInside('6')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                        <path fill="{seleccionados === 'SIN ESTATUS MIGRATORIO' ? 'white' : 'black'}" d="M12 12h4v4h-4z" />
                      </svg>
                      <p style={{ lineHeight: '10px' }}>SIN ESTATUS MIGRATORIO</p>
                    </button>
                  </div>
                </div>
              </div>
              <button
                className={seleccionados ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(21) }}
                disabled={!seleccionados}>
                {seleccionados ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div> : null}
          {/* Pagina 20 */}
          {currentPage === 21 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(20) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es tu nivel de Inglés?</h3>
                <div className="range-slider">
                  <input
                    type="range"
                    min="0"
                    max="4"
                    value={nivel.toString()}
                    onChange={handleChange}
                    step="1"
                    style={{
                      background: `linear-gradient(to right, #007bff 0%, #007bff ${nivel * 25}%, #b4b4b4 ${nivel * 25}%, #b4b4b4 100%)`,
                      borderRadius: '30px'
                    }} />
                  <div onChange={handleChange} className="slider-thumb" style={{ left: `${nivel * 24}%` }}></div>
                  <div className="options">
                    <span className={`option ${nivel === 0 ? 'selected' : ''}`}></span>
                    <span className={`option ${nivel === 1 ? 'selected' : ''}`}>Nulo</span>
                    <span className={`option ${nivel === 2 ? 'selected' : ''}`}>Bajo</span>
                    <span className={`option ${nivel === 3 ? 'selected' : ''}`}>Medio</span>
                    <span className={`option ${nivel === 4 ? 'selected' : ''}`}>Avanzado</span>
                  </div>
                </div>
              </div>
              <button
                className={nivel ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(22) }}
                disabled={nivel === 0}>
                {nivel ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div>
            : null}
          {/* Pagina 21 */}
          {currentPage === 22 ?
            <div
              className={`first-form-carrusel-page ${currentPage !== null ? 'slide-in' : ''}`}
              onAnimationEnd={() => setTransitioning(false)}>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(21) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container'>
                <h3 className='title-condition'>¿Cuál es tu nivel de Francés?</h3>
                <div className="range-slider">
                  <input
                    type="range"
                    min="0"
                    max="4"
                    value={nivelFrances.toString()}
                    onChange={handleChangeFrances}
                    step="1"
                    style={{
                      background: `linear-gradient(to right, #007bff 0%, #007bff ${nivelFrances * 25}%, #b4b4b4 ${nivelFrances * 25}%, #b4b4b4 100%)`,
                      borderRadius: '30px'
                    }} />
                  <div onChange={handleChangeFrances} className="slider-thumb" style={{ left: `${nivelFrances * 24}%` }}></div>
                  <div className="options">
                    <span className={`option ${nivelFrances === 0 ? 'selected' : ''}`}></span>
                    <span className={`option ${nivelFrances === 1 ? 'selected' : ''}`}>Nulo</span>
                    <span className={`option ${nivelFrances === 2 ? 'selected' : ''}`}>Bajo</span>
                    <span className={`option ${nivelFrances === 3 ? 'selected' : ''}`}>Medio</span>
                    <span className={`option ${nivelFrances === 4 ? 'selected' : ''}`}>Avanzado</span>
                  </div>
                </div>
              </div>
              <button
                className={nivelFrances ? 'first-test-arrow-button' : 'first-test-arrow-button-disabled'}
                onClick={() => { setCurrentPage(23) }}
                disabled={nivelFrances === 0}>
                {nivelFrances ? (
                  <img src={ArrowRight} alt="ArrowRight" />
                ) : (
                  <img src={ArrowRightDisable} alt="ArrowRightDisable" />
                )}
              </button>
            </div>
            : null}
          {/* Pagina 22 */}
          {currentPage === 23 ?
            <div className='first-form-carrusel-page'>
              <button className='first-test-arrow-button'
                onClick={() => { setCurrentPage(22) }}>
                <img src={ArrowLeft} alt="ArrowLeft" />
              </button>
              <div className='text-question-container-input'>
                <div className='input-container-pre-evaluation'>
                  <h3 className='title-condition'>Nombre completo</h3>
                  <input
                    type="text"
                    className="input-pre-evaluation"
                    placeholder="Nombre Completo"
                    onChange={handleInputNameChange}
                    value={preEvaluationAnswers.name}
                  />
                </div>
                <div className='input-container-pre-evaluation'>
                  <h3 className='title-condition'>Correo electrónico</h3>
                  <div>
                    <input
                      type="email"
                      className="input-pre-evaluation-email"
                      placeholder="Nombre Completo"
                      onChange={handleInputEmailChange}
                      value={preEvaluationAnswers.email}
                      style={{ minWidth: '15rem' }}
                    />
                    {emailError &&
                      <p style={{
                        color: 'red',
                        fontSize: '0.8rem',
                        margin: '0'
                      }}>{emailError}</p>}</div>
                </div>
              </div>
              <button
                className={preEvaluationAnswers.name && preEvaluationAnswers.email && !emailError ? 'button-form-notify' : 'button-form-notify-disable'}
                disabled={!(preEvaluationAnswers.name && preEvaluationAnswers.email && !emailError)}
                style={{ gap: '10px' }}
                onClick={() => {
                  handleShowResults
                  setShowResult(true)
                }}>
                <p style={{ color: 'white', fontSize: '0.8rem', margin: '0' }}>VER RESULTADOS</p>
              </button>
            </div>
            : null}
          {/* Fin dentro de Canada */}
        </div>
      </div>
      <div className='footer-first-form'>
        <div className='footer-first-form-image '>
          <img className='first-form-avatar' src={Avatar1} alt="Avatar1" />
          <img className='first-form-avatar' src={Avatar2} alt="Avatar2" />
          <img className='first-form-avatar' src={Avatar3} alt="Avatar3" />
          <img className='first-form-avatar' src={Avatar4} alt="Avatar4" />
          <img className='first-form-avatar' src={Avatar5} alt="Avatar5" />
          <img className='first-form-avatar' src={Avatar6} alt="Avatar6" />
        </div>
        <div className='footer-btn-text'>
          <div className='flex gap-2 items-center'>
            <p className='first-form-white-text'>Más de</p>
            <p className='first-form-white-text-big'>10.322</p>
          </div>
          <p className='first-form-white-text'>personas han confiado en nosotros</p>
        </div>
      </div>
    </div>
  );
};

export default MainPreEvaluationForm;
