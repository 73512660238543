import React from 'react'
import { observer } from 'mobx-react'
import './style/style.css'
interface IProps {
    indexArray: number,
    setNumberOfCombos: React.Dispatch<React.SetStateAction<any[]>>,
    numberOfCombos: any[],
    setProductsAfterValidation: React.Dispatch<React.SetStateAction<any[]>>
    combo:any
}
const TableElements: React.FC<IProps> = ({ indexArray, setNumberOfCombos, numberOfCombos, setProductsAfterValidation,combo }) => {
    return (
        <>
            <div style={{ gap: '20px', display: 'flex' }}>
               {combo.leve1==="sadsad" &&
                <select
                    className='select-style-shopping-sadsad'
                    defaultValue={combo.level2?combo.level2:''}
                    onChange={(e) => {
                        setNumberOfCombos((prevArray: any) => {
                            return prevArray.map((obj: any, index: number) => {
                                if (index === indexArray) {
                                    return {
                                        ...obj,
                                        level2: e.target.value,
                                    };
                                }
                                return obj;
                            });
                        });
                    }}
                >
                    <option value={""}>Seleccionar un opción</option>
                    <option value={"Permiso de trabajo cerrado"}>Permiso de trabajo cerrado</option>
                    <option value={"Permiso de trabajo abierto"} >Permiso de trabajo abierto</option>
                </select>
                }

               {combo.level1==="LMIA" &&
                <select className='select-style-shopping-LMIA'
                defaultValue={combo.level2?combo.level2:''}
                onChange={(e) => {
                    setNumberOfCombos((prevArray: any) => {
                        return prevArray.map((obj: any, index: number) => {
                            if (index === indexArray) {
                                return {
                                    ...obj,
                                    level2: e.target.value,
                                };
                            }
                            return obj;
                        });
                    });
                }}
            >
                <option value={""}>Seleccionar un opción</option> 
                    <option value={"Empleo en Quebec"}>Empleo en Quebec </option>
                    <option value={"Empleo fuera de Quebec"}>Empleo fuera de Quebec</option>
                </select>}


                {combo.level1==="Closed work permit" &&
                <select className='select-style-shopping-CWP2'
                defaultValue={combo.level2?combo.level2:''}
                onChange={(e) => {
                    setNumberOfCombos((prevArray: any) => {
                        return prevArray.map((obj: any, index: number) => {
                            if (index === indexArray) {
                                return {
                                    ...obj,
                                    level2: e.target.value,
                                };
                            }
                            return obj;
                        });
                    });
                }}
            >
                <option value={""}>Seleccionar un opción</option> 
                    <option value={"Permiso de trabajo cerrado NO incluye LMIA"}>Permiso de trabajo cerrado NO incluye LMIA</option>
                    <option value={"Permiso de trabajo cerrado incluye LMIA"}>Permiso de trabajo cerrado incluye LMIA</option>
                    <option value={"Permiso de trabajo cerrado NO incluye oferta de empleo validada"}>Permiso de trabajo cerrado NO incluye oferta de empleo validada</option>
                    <option value={"Permiso de trabajo cerrado incluye oferta de empleo validada"}>Permiso de trabajo cerrado incluye oferta de empleo validada</option>
                </select>}

                {combo.level1==="Closed work permit" &&
                <select className='select-style-shopping-CWP3'
                defaultValue={combo.level3?combo.level3:''}
                onChange={(e) => {
                    setNumberOfCombos((prevArray: any) => {
                        return prevArray.map((obj: any, index: number) => {
                            if (index === indexArray) {
                                return {
                                    ...obj,
                                    level3: e.target.value,
                                };
                            }
                            return obj;
                        });
                    });
                }}
            >
                <option value={""}>Seleccionar un opción si es necesario</option> 
                    <option value={"Trabajador extranjero fuera de Canadá"}>Trabajador extranjero fuera de Canadá</option>
                    <option value={"Trabajador extranjero en Canadá"}>Trabajador extranjero en Canadá</option>
                </select>}


                {(combo.level2==="Permiso de trabajo cerrado incluye LMIA") &&
                <select className='select-style-shopping-CWP-LMIA'
                defaultValue={combo.level4?combo.level4:''}
                onChange={(e) => {
                    setNumberOfCombos((prevArray: any) => {
                        return prevArray.map((obj: any, index: number) => {
                            if (index === indexArray) {
                                return {
                                    ...obj,
                                    level4: e.target.value,
                                };
                            }
                            return obj;
                        });
                    });
                }}
            >
                <option value={""}>Seleccionar un opción</option> 
                    <option value={"Empleo fuera de Quebec"}>Empleo fuera de Quebec</option>
                    <option value={"Empleo en Quebec"}>Empleo en Quebec</option>
                </select>}
                </div>

        </>
    )
}

export default observer(TableElements)
