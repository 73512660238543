import { Span, TableCell } from 'Components'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculatExpiredRestoration: React.FC<IProps> = ({ products }) => {
	const rootStore = useContext(RootStoreContext)
	const ExpiredPermitRestoration = products?.find(element => element.contratationProductId === "161")

	const { familyMembers, setExpiredRestoration, getExpiredRestoration, principalUsed } = rootStore.familyStore
	//const auxPrice = 400

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const arrayOfMembers: any[] = []

	familyMembers.map((member) => {

	
		if (principalUsed()?.inCanada === "si" && member.ExpiredPermit90 === "Expired Permit90" && member.purposeVisit !== "Visa Renovation"  && member.purposeVisit !== "Residencia Permanente" && member.typeOfApplication !== "PGWP" && member.purposeVisit !== 'Co-op' ) {
		 {
				
	
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca david")
				arrayOfMembers.push(memberInfo)
			}
		}
		if (principalUsed()?.inCanada === "si" && member.ExpiredPermit90 === "Expired Permit90" && member.purposeVisit !== "Visa Renovation"  && member.purposeVisit !== "Residencia Permanente" && member.typeOfApplication !== "PGWP" && member.purposeVisit !== 'Co-op'  ) {
			if (ExpiredPermitRestoration !== undefined ) {
			
					setExpiredRestoration({
						id: ExpiredPermitRestoration.id,
						productContratationId: ExpiredPermitRestoration.contratationProductId,
						description: ExpiredPermitRestoration.description,
						totalFee: ExpiredPermitRestoration.price,
						name: ExpiredPermitRestoration.name,
						quantity: 1,
						fee: ExpiredPermitRestoration.fee,
						memberInfo: arrayOfMembers,
						type: 'pf'
					})
					console.log("pasa por aca david")
				}
			}
			
	})

	
	

	return (
		<>
			{getExpiredRestoration().quantity > 0 && (<>
				<TableCell style={{}}>
					<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
					{getExpiredRestoration().name}
				</TableCell>
				<TableCell style={{ display: 'flex' }}>
					<Span>Descripcion</Span>{' '}<Description>{getExpiredRestoration().description}</Description>
				</TableCell>
				<TableCell style={{}}>
					{' '}
					<Span>Cantidad </Span>
					{getExpiredRestoration().quantity}
				</TableCell>
				<TableCell className='border-b border-indigo-500'>
					{' '}
					<Span>Precio </Span>
					CAD {formatter.format(getExpiredRestoration().totalFee)}
				</TableCell>
				{console.log("pasa por aca david")}
			</>)}
		</>
	)
}

export default observer(CalculatExpiredRestoration)