import { Span, TableCell } from 'Components'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { ETA } from 'FormBuilder/components/FamilyModule/Data/ETACountries'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}

const CalculatAtipFee: React.FC<IProps> = ({ products }) => {
	const rootStore = useContext(RootStoreContext)
	const AtipFee = products?.find(element => element.contratationProductId === "160")
	
	const { familyMembers, setAtipFee, getAtipFee, principalUsed } = rootStore.familyStore
	const auxPrice = 5

	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const arrayOfMembers: any[] = []

	familyMembers.map((member) => {

	
		if ( 
		member.wantsAtip === true  
		) {
		 {
				
	
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				console.log("pasa por aca david")
				arrayOfMembers.push(memberInfo)
			}
		}
		if (member.wantsAtip === true  ) {
			if (AtipFee !== undefined ) {
			
                setAtipFee({
						id: AtipFee.id,
						productContratationId: AtipFee.contratationProductId,
						description: AtipFee.description,
						totalFee: AtipFee.price,
						name: AtipFee.name,
						quantity: 1,
						fee: AtipFee.fee,
						memberInfo: arrayOfMembers,
						type: 'pf'
					})
					console.log("pasa por aca david")
				}
			}
			
	})

	
	

	return (
		<>
			{getAtipFee().quantity > 0 && (<>
				<TableCell style={{}}>
					<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
					{getAtipFee().name}
				</TableCell>
				<TableCell style={{ display: 'flex' }}>
					<Span>Descripcion</Span>{' '}<Description>{getAtipFee().description}</Description>
				</TableCell>
				<TableCell style={{}}>
					{' '}
					<Span>Cantidad </Span>
					{getAtipFee().quantity}
				</TableCell>
				<TableCell className='border-b border-indigo-500'>
					{' '}
					<Span>Precio </Span>
					CAD {formatter.format(getAtipFee().totalFee)}
				</TableCell>
				{console.log("pasa por aca david")}
			</>)}
		</>
	)
}

export default observer(CalculatAtipFee)