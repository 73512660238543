import { Checkbox } from 'antd'
import React, {  useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from 'Components'
import Swal from 'sweetalert2'
import './style/style.css'
import { RootStoreContext } from 'stores/rootStore'
interface IProps {
  setInfoDisclaimerStep: React.Dispatch<React.SetStateAction<number>>
}





const buttonStyle = {
  textDecoration: 'underline',
  fontSize: '1rem',
  color: '#0B76B7',
};

const circleStyle = {
  width: '20px',
  height: '20px',
  backgroundColor: '#0B76B7',
  color: 'white',
  borderRadius: '50%',
  fontSize: '1.2rem',
  verticalAlign: 'middle',
  marginLeft: '10px',
  display: 'inline-block',
  lineHeight: '25px', // Esto centrará el contenido verticalmente
};

const InfoDisclaimerSix: React.FC<IProps> = ({ setInfoDisclaimerStep }) => {
  const [next, setNext] = useState(false)
  const [showWarning, setShowWarning] = useState(false);
  const rootStore = useContext(RootStoreContext)
  const {
      principalUsed,
      currentMember,
      handleChangeFamilyMember,
      getVisaTypeFromSurvey,
    } = rootStore.familyStore
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedPurpose, setSelectedPurpose] = useState("");
    const [isnextbuttonDisabled, setIsnextbuttonDisabled] = useState(true);
    const [showExpireInput, setShowExpireInput] = useState(false); 


    const handleOptionChange = (value: any) => {
      setSelectedPurpose(value);
      setIsnextbuttonDisabled(false); // Habilitar el botón al seleccionar una opción
      // Mostrar input si se selecciona "Expired Permit"
      if (value === 'Expired Permit') {
        setShowExpireInput(true);
      } else {
        setShowExpireInput(false);
      }
    };
      const handleChange = (fieldName: string, value: string) => {
        rootStore.familyStore.currentMember.ExpiredPermit = value;
        console.log("Respuesta almacenada:", rootStore.familyStore.currentMember.ExpiredPermit);
      };

      const handleContinuarClick = () => {
        if (!isnextbuttonDisabled) {
            handleChange('typeOfApplication', selectedPurpose);
          switch (selectedPurpose) {
            case "Expired Permit":
              handleChange('typeOfApplication', "Expired Permit");

              break;
            case "Not Expired Permit":
              handleChange('typeOfApplication', "Not Expired Permit");

              break;
            default:
              break;
          }
          setInfoDisclaimerStep(5);
        }
      };

  return (
    <>
      
      <div className='flex flex-col' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , width:'100%', gap:'30px' }} >
      <div className='step-two-container-cont'>
          <div className='step-two-container'>
						<div className="stepper-container" style={{ display: 'flex', justifyContent: 'space-between', width:'80%' }}>
							{/* Step 1 */}
							<div className="step">
								<div className="circle">1</div>
								<p className="step-label">PROPÓSITO</p>
							</div>

							{/* Step 2 */}
							<div className="step selected">
								<div className="circle">2</div>
								<p className="step-label">DATOS MIGRATORIOS</p>
							</div>

							{/* Step 3 */}
							<div className="step">
								<div className="circle">3</div>
								<p className="step-label">COTIZACIÓN</p>
							</div>
						</div>
						<div className="progress-line">
							<div className="progress-line-completed">
							<div className="dot completed-dot"></div>
							<div className="checkmark-icon">
								<div className="checkmark"></div>
							</div>
						</div>
						<div className="progress-line-completed2">
							<div className="dot completed-dot"></div>
							<div className="dot completed-dot1"></div>
						</div>
						
						<div className="dot-uncompleted2"></div>
					</div>
				</div>
        </div>
			</div>

      <div className='mt-5 mb-5 pl-5 pr-5' style={{ fontFamily: 'Lato, sans-serif', display: 'flex', justifyContent: 'center' }}>
        <div className='flex flex-col' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , width:'100%', gap:'30px' }} >

        <div className='container-aplication'>
            <div style={{ padding: '30px' }}>
              <div className='titledivquestions'>
                <h1>¿Su permiso actual vence en los próximos 20 días hábiles?</h1>
              </div>
              <div className='container-radio-select flex flex-wrap gap-8'>
                <div className='container-wrapper' style={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap' }}>
                  <div>
                    <label className="radio-selector">
                      <input
                        type="radio"
                        value="Expired Permit"
                        checked={selectedPurpose === "Expired Permit"}
                        onChange={() => {
                          handleOptionChange("Expired Permit");
                        }}
                      />
                      <p>Si</p>
                    </label>
                  </div>
                  <div>
                    <label className="radio-selector">
                      <input
                        type="radio"
                        value="Not Expired Permit"
                        checked={selectedPurpose === "Not Expired Permit"}
                        onChange={() => {
                          handleOptionChange("Not Expired Permit");
                        }}
                      />
                      <p>No</p>
                    </label>
                  </div>
                </div>
                {showExpireInput && ( 
                  <div className='container-wrapper' style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <input
                      type="text"
                      placeholder="Indique en cuanto tiempo expira su permiso"
                      onChange={(e) => handleChange('ExpiredPermit', e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center' }}>
            <Button className='backbutton-disc2' onClick={() => {
              setInfoDisclaimerStep(1)
              scrollTo(0, 0)
            }}>Atras
            </Button>
            <Button className='startbutton-disc2'
              style={{
                background: next ? '' : '#E0E0E0',
                cursor: next ? '' : 'default',
              }}
              onClick={handleContinuarClick}
              disabled={isnextbuttonDisabled}>
              Continuar
              <img src="/icons/arrowwhite.png" alt="Home" style={{ width: '20px', height: '20px' }} />
            </Button>
          </div>
        </div>
      </div>

      <footer className='footer-style' >
        <div className='footer-style-continer'>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>¿Quienes somos?</strong></h3>
            <a href="https://www.immilandcanada.com/quienes-somos">Historia y Valores</a>
            <a href="https://www.immilandcanada.com/equipo-immiland">Nuestro Equipo</a>
            <a href="https://www.immilandcanada.com/testimonios">Testimonios</a>
            <a href="https://www.immilandcanada.com/tipos-de-visa/visa-de-estudio#">Preguntas Frecuentes</a>
            <a href="https://www.immilandcanada.com/terminos-y-condiciones"><strong>Términos y condiciones.</strong></a>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }} >
            <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Servicios</strong></h3>
            <a href="https://www.immilandcanada.com/migracion/inicio">Migración</a>
            <a href="https://www.immilandcanada.com/reclutamiento/inicio">Trabajo</a>
            <a href="https://www.immilandcanada.com/educacion/inicio" >Educacion</a>
            <a href="https://www.immilandcanada.com/traduccion/inicio">Traducciones</a>
            <a href="https://www.immilandcanada.com/establecimiento/inicio">Establecimiento</a>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Ubicación</strong></div>
            <div>
              <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4cc91bcf50dcd709:0x39dd1ca577308a39?source=g.page.share" >
                <div>2025 Rue Parthenais<br />suit 320<br />Montreal, Quebec,<br />Canadá</div>
              </a>
            </div>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Blog</strong></h3>
            <div>
              <a href="https://www.immilandcanada.com/post/viajar-fuera-canada-mientras-estudio">
                <div>¿Puedo viajar fuera <br />de Canada mientras estudio?</div>
              </a>
            </div>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div >
              <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>¡Sigamos en contacto!</strong></h3>
              <div ><span>Info@immilandcanada.com</span></div>
              <div style={{
                display: 'flex',
                gap: '0.5rem',
                paddingRight: '2rem',
                alignItems: 'center'
              }}>
                <a href="https://www.youtube.com/immiland">
                  <img src="https://img.icons8.com/ios-filled/25/ffffff/youtube-play.png" alt="" />
                </a>
                <a href="https://www.instagram.com/immilandcanada/">
                  <img src="https://img.icons8.com/windows/25/ffffff/instagram-new.png" alt="" />
                </a>
                <a href="https://www.facebook.com/immilandcanada">
                  <img src="https://img.icons8.com/ios-filled/25/ffffff/facebook--v1.png" alt="" />
                </a>
                <a href="https://www.tiktok.com/@immilandcanada">
                  <img src="https://img.icons8.com/ios-filled/25/ffffff/tiktok--v1.png" alt="" />
                </a>
                <a href="https://ca.linkedin.com/company/immiland-canada">
                  <img src="https://img.icons8.com/ios-glyphs/25/ffffff/linkedin-2--v1.png" alt="" />
                </a>
                <a href="https://twitter.com/immilandcanada">
                  <img src="https://img.icons8.com/material/25/ffffff/twitter--v2.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default observer(InfoDisclaimerSix)
